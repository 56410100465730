(function (window, $) {
    "use strict";

    var cookieName = "acceptedCookies";
    var daysCookiesAcceptanceValid = 365;

    function accept() {
        var divCookieAcceptanceElement = document.getElementById("divCookieAcceptance");
        divCookieAcceptanceElement.classList.remove("active");

        if (document.body.classList.contains("cookie-message-active"))
            document.body.classList.remove("cookie-message-active");
    }

    function showCookieAcceptance(divCookieAcceptanceElement) {
        divCookieAcceptanceElement.classList.add("active");

        //add a class to body
        if (!document.body.classList.contains("cookie-message-active"))
            document.body.classList.add("cookie-message-active");

        var cookie = cookieName + "=true; path=/";
        var now = new Date();
        now.setTime(now.getTime() + 1000 * 60 * 60 * 24 * daysCookiesAcceptanceValid);
        cookie += "; expires=" + now.toUTCString();
        document.cookie = cookie;

        var acceptElement = document.getElementById("inputAcceptCookies");
        acceptElement.addEventListener("click", accept, { once: true });
    }

    function init() {
        var $cookieContainer = $("#cookieContentContainer");
        if ($cookieContainer.length === 0) {
            return;
        }

        var divTill = document.getElementById("divTill");
        if (divTill) {
            return;
        }

        if (
            window.location.search.indexOf("disablecookieacceptancepopup") !== -1 ||
            window.location.search.indexOf("disablepopups") !== -1
        ) {
            return;
        }

        var divCookieAcceptanceElement = document.getElementById("divCookieAcceptance");
        if (!divCookieAcceptanceElement) {
            return;
        }

        if (
            !document.cookie.split(";").some(function (item) {
                return item.trim().search(cookieName + "=") > -1;
            })
        ) {
            showCookieAcceptance(divCookieAcceptanceElement);
        }
    }

    function OptanonWrapper() {
        if (window.IsMobileView()) {
            setupMobileCookieOptions("OTMobileOptions", $("li.cookiePref > a"));
        } else {
            setupDesktopCookieOptions("OTDesktopOptions", $(".footerInfo"));
        }
        function setupDesktopCookieOptions(itemSelector, $menuSelector) {
            if (!$("#" + itemSelector).length) {
                var $item = $menuSelector.find("li.cookiePref > a");
                $item.attr("id", itemSelector);
                $item.click(function (e) {
                    e.preventDefault();
                    OneTrust.ToggleInfoDisplay();
                });
            }
        }
        function setupMobileCookieOptions(itemSelector, $menuSelector) {
            if (!$("#" + itemSelector).length) {
                $menuSelector.attr("id", itemSelector);
                $menuSelector.click(function (e) {
                    e.preventDefault();
                    OneTrust.ToggleInfoDisplay();
                });
            }
        }
        if (typeof window.oneTrustUpdated === "function") {
            window.oneTrustUpdated();
        }
    }
    window.OptanonWrapper = OptanonWrapper;

    (function ready() {
        if (document.readyState !== "loading") {
            init();
        } else {
            document.addEventListener("DOMContentLoaded", function () {
                init();
            });
        }
    })();
})(window, window.jQuery);
